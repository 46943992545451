import axios, { AxiosResponse } from "axios";
import store from "store2"
import { ApiResponse } from "../../models"
import { NotificationManager } from "../../components/common/react-notifications";

import {
  loginUserSuccess, loginUserError, logoutUser
} from './actions';
import { AuthClient, AuthenticateRequest } from "../../api/ApiClient";
import { API_BASE } from "../../api/ApiSettings";
import { BASE_URL } from "../../api/settings";

export function login(data: any, history) {
  return async (dispatch) => {
    var authClient = new AuthClient(API_BASE)

    var response: AxiosResponse<ApiResponse<{ Id: number, Login: string, UserType: number, Token: string }>> = await axios.post(`${BASE_URL}/home/login`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": 'application/json',
          "X-Requested-With": "XMLHttpRequest",
        }
      }
    );

    var response2 = await authClient.authenticate(new AuthenticateRequest({ login: data.login, password: data.password }));
    if (response2.error == null) {
      store("token", response2.token);
    }

    if (response.data.Error) {
      dispatch(loginUserError(response.data.Error));
      NotificationManager.warning(response.data.Error, 'Login Error', 3000, null, null, '');
    } else {
      localStorage.setItem("loginData", JSON.stringify(response.data.Data));
      dispatch(loginUserSuccess({ id: response.data.Data.Id, uid: response.data.Data.Login, type: response.data.Data.UserType, token: response.data.Data.Token }));
      history.push('/');
    }


  }
}

export function logout(history) {
  return (dispatch) => {
    localStorage.removeItem("user_id");
    store.remove("token");
    store.remove("loginData");
    dispatch(logoutUser(history));
  }
}
