import { AxiosRequestConfig } from "axios"
import { API_BASE_URL, API_WEB_ORDERING_BASE_URL } from "./MainSettings";

export const BASE_URL = API_BASE_URL
export const CONFIG: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
        "Content-Type": 'application/json',
        "X-Requested-With": "XMLHttpRequest",
    }
}

export const WEB_ORDERING_BASE_URL = API_WEB_ORDERING_BASE_URL;